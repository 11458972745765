:root {
    --color-neon: 254, 11, 239;
    --bs-color-neon: rgb(var(--color-neon));
    --bs-link-color-rgb: var(--color-neon);
    --bs-link-color: rgba(var(--color-neon), 1.0);
    --bs-link-hover-color-rgb: var(--bs-link-color-rgb);
    --bs-link-hover-color: rgba(var(--bs-link-hover-color-rgb), 0.5);
    --bs-index-color: rgba(255, 255, 255, 0.75);
    --bs-index-hover-color: rgba(255, 255, 255, 1.0);
}

.background-neon {
    background-color: var(--bs-color-neon);
}

.background-purple {
    background-color: #23123B
}

.blog-card {
    width:75%;
}

.blog-header{
    padding-right: 3rem;
    padding-left: 3rem;
}

.blog-paragraph {
    padding: 1rem 3rem;
    font-size:1.25rem;
}

@media only screen and (max-width: 1024px) {
    .blog-card {
        width: 100%;
    }
    .comment-section {
        width: 100% !important;
    }
}

@media only screen and (max-width: 768px) {
    .blog-paragraph {
        padding: 1rem 1rem;
        font-size: 1.25rem;
    }
    .blog-header{
        padding-right: 1rem;
        padding-left: 1rem;
    }
}

.card-with-effect {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    background: #fff;
    transition: all 0.5s ease;
    cursor: pointer;
    user-select: none;
    z-index: 10;
    overflow: hidden
}

.card-with-effect:hover {
    color: #fff;
    transform: scale(1.025);
    box-shadow: rgba(0, 0, 0, 0.24) 0px 5px 10px;
}

.card-with-effect .backgroundEffect {
    bottom: 0;
    height: 0px;
    width: 100%;
}

.card-with-effect:hover .backgroundEffect {
    bottom: 0;
    height: 221px;
    width: 100%;
    position: absolute;
    z-index: -1;
    background: #23123B;
}

.card-with-effect .card-link-hidden {
    opacity: 0;
    overflow: hidden;
    height: 0;
    width: 0;
    display: block;
}

.fit-all {
    width: 100%;
    object-fit: cover;
    object-position: 50% 50%;
}

.fs-7 {
    font-size: 0.75rem !important;
}

.fs-8 {
    font-size: 0.5rem !important;
}

.active-index {
    color: var(--bs-color-neon) !important;
}

.grecaptcha-badge {
    visibility: hidden;
}

.pb-index a {
    color: var(--bs-index-color);
}

.pb-index a:hover {
    color: var(--bs-index-hover-color);
}

.pb-index {
    padding-bottom: 1em;
}

.ps-index-HEADING_1 {
    font-size: 1.2rem;
    padding-left: 1em;
}

.ps-index-HEADING_2 {
    font-size: 1rem;
    padding-left: 2em;
}

.ps-index-HEADING_3 {
    font-size: 0.8rem;
    padding-left: 3em;
}

.text-neon, .thumbs-up-success {
    color: var(--bs-color-neon) !important;
}

.text-string {
    color: #F08D49
}

.tada {
    animation: tada 1s 1;
}

@keyframes tada {
    0% {
        transform: scale3d(1, 1, 1);
    }

    10%, 20% {
        transform: scale3d(.9, .9, .9) rotate3d(0, 0, 1, -3deg);
    }

    30%, 50%, 70%, 90% {
        transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
    }

    40%, 60%, 80% {
        transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
    }

    100% {
        transform: scale3d(1, 1, 1);
    }
}

.pulse {
    animation: pulse-animation 2s 1;
}

@keyframes pulse-animation {
    0% {
        box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2);
    }
    100% {
        box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
    }
}
