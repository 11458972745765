.comment-box {
	border-radius: 10px;
	padding: 20px;
	margin-bottom: 20px;
	transition: transform 0.2s;
}

.comment-box:hover {
	transform: translateY(-2px);
	box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

.comment-input {
	border-radius: 20px;
	padding: 15px 20px;
	transition: all 0.3s;
}

.comment-input:focus {
	box-shadow: none;
}

.comment-actions button {
	font-size: 0.9rem;
}

.comment-time {
	color: #adb5bd;
	font-size: 0.85rem;
}
